import { ref, computed } from 'vue';
import * as XLSX from 'xlsx';
import _ from 'lodash';

export const useAddress = (initialProvince = '', initialCity = '', initialDistrict = '') => {
  const provinces = ref([]);
  const selectedProvince = ref(initialProvince);
  const selectedCity = ref(initialCity);
  const selectedDistrict = ref(initialDistrict);
  const groupedData = ref({});

  const loadAddressData = async () => {
    const response = await fetch('/data/flash-address.xlsx');
    const arrayBuffer = await response.arrayBuffer();
    const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    const data = jsonData.slice(1).map(row => ({
      province: row[1],
      city_name: row[4],
      district_name: row[7],
      postal_code: row[9]
    }));

    groupedData.value = _.groupBy(data, 'province');
    provinces.value = Object.keys(groupedData.value);
    console.log(provinces.value,'provinces')
  };

  const cityNames = computed(() => {
    if (!selectedProvince.value) return [];
    return _.uniq(groupedData.value[selectedProvince.value]?.map(item => item.city_name) || []);
  });

  const districtNames = computed(() => {
    if (!selectedProvince.value || !selectedCity.value) return [];
    return _.uniq(groupedData.value[selectedProvince.value]
      ?.filter(item => item.city_name === selectedCity.value)
      .map(item => item.district_name) || []);
  });

  const postalCodes = computed(() => {
    if (!selectedProvince.value || !selectedCity.value || !selectedDistrict.value) return [];
    return _.uniq(groupedData.value[selectedProvince.value]
      ?.filter(item => item.city_name === selectedCity.value && item.district_name === selectedDistrict.value)
      .map(item => item.postal_code) || []);
  });

  const setSelectedProvince = (province) => {
    selectedProvince.value = province;
    selectedCity.value = '';
    selectedDistrict.value = '';
  };

  const setSelectedCity = (city) => {
    selectedCity.value = city;
    selectedDistrict.value = '';
  };

  const setSelectedDistrict = (district) => {
    selectedDistrict.value = district;
  };

  loadAddressData();

  return {
    provinces,
    selectedProvince,
    cityNames,
    districtNames,
    postalCodes,
    setSelectedProvince,
    setSelectedCity,
    setSelectedDistrict,
    selectedCity,
    selectedDistrict
  };
}
